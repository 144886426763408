@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;900&display=swap");

$primaryColor: #a8c657;
$secondyColor: #585856;
$thirdColor: #646462;
$fourthColor: #d9d9d9;
$fifthColor: #9c9c9a;

.title-page {
  font-family: "LATO";
  font-size: 25px;
  text-transform: uppercase;
}
.text-tips {
  width: 80%;
  font-family: "LATO", sans-serif;
  font-size: 16px;
  strong {
    text-transform: uppercase;
  }
}
input[type="file"] {
  font-family: "LATO", sans-serif;
  width: 60%;
  border: none;
}
.error-message {
  color: #ff0000;
  font-family: "LATO";
  font-weight: 900;
  font-size: 16px;
}
.subTitle {
  font-family: "LATO";
  text-transform: uppercase;
  font-weight: 900;
  font-size: 20px;
  color: #585856;
}
p {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
